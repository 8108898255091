<template>
  <div>
    <div class="card-toolbar mb-5">
<!--     -->
      <button  v-if="$can('cash_category.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('cash_category.new_cash_category') }}
      </button>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name">{{ $t('name') }}</label>
              <input v-model="filters.name" type="text" id="name" class="form-control">
            </div>

            <div class="form-group col-md-6">
              <label for="name">{{ $t('status') }}</label>
              <select name="" id="is_active" v-model="filters.is_active" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{ $t('search') }}</span>
                                </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{ $t('reset_search') }}</span>
                                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">

      <div class="card-body">
        <div class="d-flex justify-content-end mb-5">
          <custom-export-data v-if="$can('cash_category.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
        </div>
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('cash_category.change_status')"
                             size="lg" @change="changeStatus(props.row.id, props.row.is_active)"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
            <b-form-checkbox v-else
                             size="lg" :disabled="true"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('cash_category.update')" @click="showModal(props.row)">mdi-pencil</v-icon>
            <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('cash_category.delete')" @click="deleteCashCategories(props.row)">mdi-delete</v-icon>
          </template>
        </v-server-table>
        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="modal" hide-footer :title="$t('cash_category.cash_category')">
      <div class="row">
        <div class="col-md-6 mb-5">
          <label for="name">
            {{ $t('cash_category.name') }}<span class="text-danger">*</span>
          </label>
          <input v-model="data.name" type="text" id="name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''">
          <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
              {{ validation.name[0] }}
          </span>
        </div>


        <div class="col-lg-6 mb-5">
          <label>{{ $t('cash_category.currency') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
              <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
            </select>
            <div class="input-group-prepend">
              <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
            </div>
          </div>
          <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
              {{ validation.currency_id[0] }}
          </span>
        </div>
        <div class="col-md-6 mb-5">
          <label for="name">
            {{ $t('cash_category.value') }}<span class="text-danger">*</span>
          </label>
          <input v-model="data.value" type="number" id="name" class="form-control" :class="validation && validation.value ? 'is-invalid' : ''">
          <span v-if="validation && validation.value" class="fv-plugins-message-container invalid-feedback">
              {{ validation.value[0] }}
          </span>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>{{ $t('cash_category.status') }}:</label>
            <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch></b-form-checkbox>
          </div>
        </div>
        <div class="col-lg-6 mb-5">
          <label>{{ $t('cash_category.image') }}</label>
          <upload-file
              @file="listenerAttachment"
              :inner-id="'image'"
              :placeholder="$t('cash_category.upload_image')"
              :upload="dir_upload"
              :start-link="'base'"
              v-bind:valueprop="{name:'attachment',path:image_url}" v-if="reloadUploadAttachment">
          </upload-file>
          <span v-if="validation && validation.image" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.image[0] }}
                            </span>
        </div>

        <div class="col-lg-12 mb-5">
          <label>{{ $t('cash_category.description') }}</label>
          <textarea type="text" v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
          <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
        </div>
      </div>


      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{ $t('close') }}</b-button>
        <b-button class="mt-2 mr-1" variant="primary" @click="save">{{ $t('save') }}</b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index",
  components: {},
  data() {
    return {
      mainRoute: 'settings/cash_categories',
      routeChangeStatus: 'settings/cash_category/change-status',
      mainRouteDependency: 'base/dependency',

      data: {
        name: '',
        currency_id: '',
        is_active: true,
        value: '',
        description: '',
        image:'',
      },
      currencies:[],
      image_url: null,
      reloadUploadAttachment: true,
      dir_upload: 'cash_category',

      showAdvancedSearch: false,
      filter: {
        sortBy: 'id',
      },
      filters: {
        name: '',
        is_active: '',
      },
      columns: ['name', 'currency_name',  'value', 'created_at', 'status', 'actions'],
      validation: null,

      isEditing: false,
      id: null,
      dataList: [],
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('cash_category.name')] = 'name';
      fields[this.$t('cash_category.currency')] = 'currency_name';
      fields[this.$t('cash_category.value')] = 'value';
      fields[this.$t('cash_category.status')] = 'status';
      fields[this.$t('created_at')] = 'created_at';
      return fields;
    },
    fileName: function () {
      return this.$t('cash_category.cash_category');
    },
    options: function () {
      let that = this;
      return {

        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('cash_category.name'),
          currency_name: that.$t('cash_category.currency'),
          value: that.$t('cash_category.value'),
          created_at: that.$t('created_at'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {

          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.cash_categories")}]);
    this.getCurrencies();
  },
  methods: {
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        is_active: (status ? 1 : 0),
      }).then((response) => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = '';
      this.filters.is_active = '';

      this.$refs.table.refresh();
    },
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    actionDelete(row) {
      ApiService.delete(`${this.mainRoute}/${row.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    deleteCashCategories(row) {
      this.$confirmAlert('', this.actionDelete, row);
    },

    showModal(data) {
      if (!data.id) {
        this.reset();
        this.$refs['modal'].show()
      } else {
        this.isEditing = true;
        this.id = data.id;
        this.getData(data);
        this.$refs['modal'].show();
      }

    },
    hideModal() {
      this.reset();
      this.$refs['modal'].hide()
    },
    toggleModal() {
      this.$refs['modal'].toggle('#toggle-btn')
    },
    create() {
      ApiService.post(this.mainRoute, this.data).then((response) => {
        this.$successAlert(response.data.message);
        this.hideModal();
        this.afterSave();
      }).catch((errors) => {
        this.$errorAlert(errors);
        this.validation = errors.response ? errors.response.data.errors : null;
      });
    },
    update() {
      ApiService.put(`${this.mainRoute}/${this.id}`, {
        id: this.id,
        ...this.data,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.validation = null;
        this.hideModal();
        this.afterSave();
      })
          .catch((errors) => {
            this.$errorAlert(errors);
            this.validation = errors.response ? errors.response.data.errors : null;
          });
    },
    getData(data) {
      this.reloadUploadAttachment = false;
      this.data.name = data.name;
      this.data.value = data.value;
      this.data.currency_id = data.currency_id;
      this.data.is_active = data.is_active;
      this.data.description = data.description;
      this.data.currency_id = data.currency_id;
      this.data.image = data.image;
      this.image_url = data.image_url;
      this.reloadUploadAttachment = true;

    },
    reset() {
      this.data = {
        name: '',
        currency_id: '',
        is_active: true,
        value: '',
        description: '',
        image:'',
      };
      this.validation = [];
      this.isEditing = false;
      this.image_url = '';
    },

    afterSave() {
      this.data = {
        name: '',
        currency_id: '',
        is_active: true,
        value: '',
        description: '',
        image:'',
      };
      this.validation = null;
      this.image_url = '';
      this.getFetch();
    },
    listenerAttachment(event) {
      if (event) {
        this.image_url = event.pathDB;
        this.data.image = event.name;
      }
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
  },
};
</script>
